import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import * as firebase from 'firebase/app';
import { User } from '../interfaces/user';
import { DataStore } from './data.services';

@Injectable( {
    providedIn: 'root',
} )

export class UserAuthServices
{
    HAS_LOGGED_IN = 'hasLoggedIn';


    constructor( public storage: Storage, private dataStore: DataStore )
    {
        window.addEventListener( 'user:logout', this.userLoggedOutEventHandler );
    }

    login( user: User ): Promise<any>
    {
        if (user.isAdmin) {
            return this.storage.set( this.HAS_LOGGED_IN, true ).then( () =>
            {
                this.setUser( user ).then( () =>
                {
                    console.log('LOGIN', user)
                    localStorage.setItem( 'current_user_email', JSON.stringify( user.email ) );
                    this.dataStore.logActivity( 'logged in', user );
                    return window.dispatchEvent( new CustomEvent( 'user:login' ) );
                } );
            } );
        } else {
            console.log('NOT ADMIN')
        }
    }

    setUser( user: User ): Promise<any>
    {
        console.log('set')
        user.isAdmin = true;
        this.dataStore.addAuthenticatedUser( user );
        return this.storage.set( 'user', user );
    }

    getUser(): Promise<User>
    {
        return this.storage.get( 'user' ).then( ( value: User ) =>
        {
            return value;
        } );
    }

    getUsername(): Promise<string>
    {
        return this.storage.get( 'user' ).then( ( value: User ) =>
        {
            return value.displayName;
        } );
    }

    getUserEmail(): Promise<string>
    {
        return this.storage.get( 'user' ).then( ( value: User ) =>
        {
            return value.email;
        } );
    }

    isLoggedIn(): Promise<boolean>
    {
        return this.storage.get( this.HAS_LOGGED_IN ).then( ( value ) =>
        {
            return value === true;
        } );
    }

    logout(): Promise<any>
    {
        this.dataStore.logActivity( 'logged out', null );
        return firebase
            .auth()
            .signOut()
            .then( () =>
            {
                this.storage
                    .remove( this.HAS_LOGGED_IN )
                    .then( () =>
                    {
                        localStorage.clear();
                        this.storage.clear();
                    } )
                    .then( () =>
                    {
                        window.dispatchEvent( new CustomEvent( 'user:logout' ) );
                    } );
            } );
    }

    userLoggedOutEventHandler( $event )
    {    }
}
