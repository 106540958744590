// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    firebaseConfig: {
        apiKey: 'AIzaSyAv2JYBJxbEOtlr14NLtsa6AD_RuCyWL9w',
        authDomain: 'revolutionapp-test-e106d.firebaseapp.com',
        databaseURL: 'https://revolutionapp-test-e106d-default-rtdb.firebaseio.com',
        projectId: 'revolutionapp-test-e106d',
        storageBucket: 'revolutionapp-test-e106d.appspot.com',
        messagingSenderId: '924222365869',
        appId: '1:924222365869:web:15de9fc622ab212c29a624',
        vapidKey: 'BFzsiY4OTXx_KYUSGeKWWQ8qHooLCmfdaL7TrRf8OpclcYiA1dN39BlOS5Fk2B779Q-E83uUduI5R00hUqr_37Q',
        type: "service_account",
        project_id: "revolutionapp-test-e106d",
        private_key_id: "7f4723b2383e61661161ef88e50a76b384589992",
        private_key: "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDUHGxpZQWvxav1\n1dkdKHx2BVzuWUdJ7QAAhnFypJwZHRjyk3Bf2w33ZHFDHVqC2BMuMjkgMhLzn3bg\nUI6ORGqWC4GhzBcnr6JHkC/dcooBhH01kucWnCKI8Q54rRzUu+hSDfVRs7Ht4TO9\ni3uFoPy2+/aWqTUhuOdgtNI3iXKsol0s7pOu9BaBKbxuVVGGmbNw/iLqqO+ToDYO\n/ExcB5p6S2+CAQDDtcpDH7YI9lgbBrxY+gVUm6NtX7gFxdWR1ikfxYKqRK9V9KaH\nW6X6AGYpJIh37NLHhusr6bvF1tRTkuUedEsYjm5GPJQJWNsEToEVfC5iAlQ85+ET\nmWqFuC1TAgMBAAECgf85K3AM+C2QMrcmi3z5XvAs30Us+OM+CaL2gUYSoEtyeWU/\n/H1NWL1q8KeWb1L18Uc6LE85XkW9ZD2mOBA0SDxyNKe79EZJbA1RPIuqJDUOM6eL\nyQyAC5VDU7WS0RX0QLFdbGWyn7xVYV2QuQeR/NIgf8j20qR/clbqudHvJhzCaoL+\ndKC25D1caGSTriaYB0Yv+7Pduij8+TWNpjLwQ+lM900huyedqMkwCA/VUtG8qKgy\nsyXCuGmjaRIcA0NDSf5bQy4JiFB2/M5Uv4UVkUSx1DLvGjC1ywbRcRWBsqUfQs/S\ntOYVZyjYx8Jq+hVZtbi9MVQ0yHsfV3YSTVgdJxkCgYEA8fVVJ0PifpdlOJy0R/jE\n3phGCOr5eJzqBoL0epv8fVVOCqNDt1C14VFlsJn1jPdLdbarpYGD6sJd2NwJpbXk\n/gdwRpn7EmcsS6613+lPSo2PrODllxZI0N8wBhLJ5x6W3ZfFRt4rTSmSAwWHs9AI\nV0/ob0U7pL+gZ/TajDCKkvUCgYEA4GupqtwoBkfURM2lwgtPwX64gnEa9iood8ug\n2rMwxbYj+Om/7x5bLEtymldP/tt3CBe0+fFDmaf0HE6G3TamA5k8++Eff0L/AJ7w\nh51ad2ORitixcFSM/g+gGhCdD0QsgaYmxe8Qmp6+k/1f/S2e8HRFlNIoT2BBmPqF\nrI6yYicCgYAtCqaXcKOxPS2ypDlLTJwZc+9VqahXM569J5OrX25ZRpAImuv4LCYv\nk5+CiKaVX1PEzgSWAs4z3Yrq28tP9dRzFZ9pBTEf8+TjEdr5m80O69tbK2KSBav4\ndhPSjZzYUXF0PtSSpLka3Co6NcVVS9zBpDZ4wEKiIKoTsBkfD8o8IQKBgQDZvjxB\n5hgDd//xveJdMqJwqCYXkexJ+b+H9A+fAouI2V9vrESomjh18sJAFAUuzvtf47iZ\nZchkNrw4Fsztp49OIIk2DkiELpnCnXPc8QSMoBPftr36sl6WRszdXU82N7xDEtW9\n6GWgz/MzHXzb5j63KOk2Ku4njco3xqKaOV3EswKBgQC2nidw0+BDZD4+TiAlBD4h\nCkNnmsKvHaMeFkd6w1Nb1wE2KRq3StdooIS5ld80fF7ZXDR+vfyQPjsLdMnrLQa5\niDgSIDIRMmE75nFH6iPlsbdukJRPR1GQnvEDY1eCIpeHCqamBZSlhvK0y/DDCFdw\nV+cYF0GaBHY31xagCC7Lmg==\n-----END PRIVATE KEY-----\n",
        client_email: "firebase-adminsdk-2pt7c@revolutionapp-test-e106d.iam.gserviceaccount.com",
        client_id: "106899928596024499655",
        auth_uri: "https://accounts.google.com/o/oauth2/auth",
        token_uri: "https://oauth2.googleapis.com/token",
        auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
        client_x509_cert_url: "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-2pt7c%40revolutionapp-test-e106d.iam.gserviceaccount.com",      
        firebase_Bucket: 'gs://revolutionapp-test-e106d.appspot.com'
    },
    zoomConfig: {
        apiKey: 'ni2McdRqS-a_v4R0pXYJZw',
        apiSecret: 'CVWF4lXKzOhmhKj99SqPjRDiJbYqMWwH1OcW',
        meetingNumber: 9751547042,
        userEmail: 'dispatch@revstaff.com',
        passWord: 'RigBox123'
    },
    rigboxConfig: {
        // apiURL: 'http://local.api.rigbox.com', 
        apiURL: 'https://test-api.rigbox.com',
        // apiURL: 'https://api.rigbox.com',
        revolutionStaffingApiHeaders: {
            Authorization: 'Basic cmlnYm94YXBpdXNlcjpiNDUxNzE0NzBjMmY0NDExYjM2MWNlZDM1NWUzZjM0YQ==',//'Basic VEFMdU9QYVI6RE9zeGVHUWVKI2hyb0c3I0w4bFVJY2lLIyFZSEpha0Y=',
            RigBoxId: '1102',
            'Company-Code': 'test',
            // 'Company-Code': 'Rev'
        },
        helpUnlimitedApiHeaders: {
            Authorization: 'Basic cmlnYm94YXBpdXNlcjpiNDUxNzE0NzBjMmY0NDExYjM2MWNlZDM1NWUzZjM0YQ==',//'Basic S0FnZW9kZW46dHU3OTUjTkIjek5EODleczczc3ZONCRWTnlwQ0RMcjk=',
            RigBoxId: '1005',
            'Company-Code': 'test',
            // 'Company-Code': 'HU'
        },
        essentialStaffingApiHeaders: {
            Authorization: 'Basic cmlnYm94YXBpdXNlcjpiNDUxNzE0NzBjMmY0NDExYjM2MWNlZDM1NWUzZjM0YQ==',//'Basic aEFnVW1Qc3k6elZRT0JeI0NSVnVnVG9qSTRpRmZyUSN6IUM0eSFhdjA=',
            RigBoxId: '1102',
            'Company-Code': 'test',
            // 'Company-Code': 'ES'
        },
        sendNewUsersOnly: false
    }
};

