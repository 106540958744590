import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
   {
     path: 'applicant/',
     redirectTo: '',
     pathMatch: 'full'
  },
  {
    path: 'applicant/:email',
    loadChildren: () => import('./applicant/applicant.module').then( m => m.ApplicantPageModule)
  },
  {
    path: 'add-stage',
    loadChildren: () => import('./add-stage/add-stage.module').then( m => m.AddStagePageModule)
  },
  {
    path: 'user-manage',
    loadChildren: () => import('./user-manage/user-manage.module').then( m => m.UserManagePageModule)
  },
  {
    path: 'video',
    loadChildren: () => import('./video/video.module').then( m => m.VideoPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./applicant/applicant.module').then( m => m.ApplicantPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
} )

export class AppRoutingModule {}
