import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage';
import { OrderModule } from 'ngx-order-pipe';
import { TimeAgoPipe } from 'time-ago-pipe';
import { IonicStorageModule } from '@ionic/storage';
import { ReactiveFormsModule } from '@angular/forms';
import { FirebaseUIModule, firebase, firebaseui } from 'firebaseui-angular';
import { AutosizeModule } from 'ngx-autosize';
import { HttpClientModule } from '@angular/common/http';



const firebaseUiAuthConfig: firebaseui.auth.Config = {
    signInFlow: 'redirect',
    signInOptions: [
        {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            requireDisplayName: true,
        }

    ],
    tosUrl: '<your-tos-link>',
    privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
    credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
};

@NgModule( {
    declarations: [AppComponent, TimeAgoPipe],
    entryComponents: [],
    imports: [
        BrowserModule,
        HttpClientModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        ReactiveFormsModule,
        AngularFireModule.initializeApp( environment.firebaseConfig ),
        AngularFirestoreModule,
        AngularFireAuthModule, AutosizeModule,
        FirebaseUIModule.forRoot( firebaseUiAuthConfig ),
        AngularFireStorageModule, OrderModule, IonicStorageModule.forRoot(),
        ServiceWorkerModule.register( 'combined-sw.js', { enabled: environment.production } )
    ],
    providers: [
        StatusBar,
        SplashScreen,
        [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
            { provide: BUCKET, useValue: environment.firebaseConfig.firebase_Bucket }]
    ],
    bootstrap: [AppComponent]
} )
export class AppModule { }
