import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DataStore } from '../providers/data.services';
@Component({
  selector: 'app-user-manage',
  templateUrl: './user-manage.page.html',
  styleUrls: ['./user-manage.page.scss'],
})
export class UserManagePage implements OnInit {
  users: any[];
  constructor(public modalController: ModalController,
    private dataService: DataStore) { }

  ngOnInit() {
    this.dataService.authenticatedUsers.subscribe(data => {
      this.users = data.filter(x => !x.disabled);
    });
  }
  dismissModal() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  makeAdmin(user) {
    this.dataService.makeAdmin(user)
  }
}