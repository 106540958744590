import { Component, OnInit, AfterViewInit } from '@angular/core';
import { first, map } from 'rxjs/operators';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DataStore } from './providers/data.services';
import { of, Subscription } from 'rxjs';
import { FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult, } from 'firebaseui-angular';
import * as firebase from 'firebase/app';
import { NotificationsService } from './notifications.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserAuthServices } from './providers/user-auth-services';
import { Router } from '@angular/router';
import { UserManagePage } from './user-manage/user-manage.page';


@Component( {
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
} )

export class AppComponent implements OnInit, AfterViewInit
{
    [x: string]: any;
    private subscription: Subscription;
    isLoggedIn = false;
    public selectedIndex = 0;
    public applicants: any[];
    public dbApplicants: any[];
    order = 'lastActiveT';
    searching: any = false;
    showAllAppTypes: any = true;
    showAllStatuses: any = true;
    cc = 'primary';
    url1: string;
    loggedInUser: any;

    isHiredVisible: string;
    isRejectedVisible: string;
    isOnHoldVisible: string;
    isApplicantVisible: string;
    isShowAllVisible: string;
    isDriverVisible: string;
    isAzDriverVisible: string;
    isDzDriverVisible: string;

    isLabourVisible: string;
    isClericalVisible: string;
    isShowAllAppTypesVisible: string;
    selectedAppTypeFilter: string | null;
    selectedAppStatusFilter: string | null;
    selectedLastActiveFilter: boolean;

    isLastActiveVisible: string;
    isShowAllActiveVisible: string;

    companies: any[];
    selectedCompany: any;

    constructor(
        private platform: Platform,
        private router: Router,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private dataService: DataStore,
        private notificationsService: NotificationsService,
        private angularFireAuth: AngularFireAuth,
        private userService: UserAuthServices,
        private toastCtrl: ToastController,
        private modalController: ModalController )
    {
        this.angularFireAuth.authState.subscribe( this.firebaseAuthChangeListener );
        const loginStatus = window.localStorage.getItem( 'isLoggedIn' );
        this.isLoggedIn = loginStatus === 'true';

        this.initializeApp();
        this.clearFilters();

        this.dataService.companies.subscribe( data =>
        {
            this.companies = data;
            this.selectedCompany = "all";
        } );
    }

    firebaseAuthChangeListener( data: any )
    {
        if ( data && ( this.loggedInUser === undefined ) )
        {
            const user = {
                uid: data.uid,
                email: data.email,
                displayName: data.displayName,
                photoURL: data.photoURL,
                emailVerified: data.emailVerified,
                providerId: data.providerId,
                tenantId: data.tenantId,
                refreshToken: data.refreshToken,
            };
            this.loggedInUser = user;
            this.isLoggedIn = true;
            window.localStorage.setItem( 'loggedInUser', JSON.stringify( user ) );
        }
    }

    clearFilters()
    {
        this.isHiredVisible = 'medium';
        this.isRejectedVisible = 'medium';
        this.isOnHoldVisible = 'medium';
        this.isApplicantVisible = 'medium';
        this.isShowAllVisible = 'medium';

        this.isDriverVisible = 'medium';
        this.isAzDriverVisible = 'medium';
        this.isDzDriverVisible = 'medium';
        this.isLabourVisible = 'medium';
        this.isClericalVisible = 'medium';
        this.isShowAllAppTypesVisible = 'medium';

        this.isLastActiveVisible = 'medium';
        this.isShowAllActiveVisible = 'medium';
    }

    async addSelfie()
    {
        this.applicants.forEach( element =>
        {
            element.lastActiveT = new Date( element.lastActive ).valueOf();
            if ( element.applicationStatus == undefined )
                element.applicationStatus = 'Applicant';

            element.url = this.dataService.getStoredDocument( element.name.replace( ' ', '_' ) + '/selfie.jpeg' );
        } );
    }

    async initializeApplicants(): Promise<any>
    {
        this.dataService.getMobileUsers().subscribe( data =>
        {
            this.dbApplicants = data.filter( x => !x.archived && x.name );

            this.dbApplicants.forEach( element =>
            {
                if ( element.applicationStatus == undefined )
                    element.applicationStatus = 'Applicant';

                element.lastActiveT = new Date( element.lastActive ).valueOf();
                if ( element.selfie && element.name !== undefined )
                    element.url = this.dataService.getStoredDocument( element ?.name.replace( ' ', '_' ) + '/selfie.jpeg');
                else
                    element.url = of( '../../assets/icon/camera-outline.png' );
            } );

            if ( ( !this.searching ) && this.showAllAppTypes && this.showAllStatuses )
            {
                this.applicants = this.dbApplicants;
            }

            this.inAppMessageAlert();
        } );
    }

    async filterList( $event )
    {
        this.initializeApplicants();
        const searchTerm = $event.srcElement.value;
        if ( !searchTerm )
        {
            this.searching = false;
            return;
        }
        else
        {
            this.searching = true;
        }

        this.applicants = this.applicants.filter( applicant =>
        {
            if ( applicant.name && searchTerm && applicant.Company == this.selectedCompany )
            {
                return ( applicant.name.toLowerCase().indexOf( searchTerm.toLowerCase() ) > -1 );
            }
        } );

        this.addSelfie();
    }

    filterStatus( status: string )
    {
        this.selectedAppStatusFilter = status;

        if ( status == 'Applicant' )
        {
            this.isShowAllVisible = 'medium';
            this.isRejectedVisible = 'medium';
            this.isHiredVisible = 'medium';
            this.isOnHoldVisible = 'medium';
            this.isApplicantVisible = 'warning';
        }

        if ( status == 'Rejected' )
        {
            this.isShowAllVisible = 'medium';
            this.isRejectedVisible = 'danger';
            this.isHiredVisible = 'medium';
            this.isOnHoldVisible = 'medium';
            this.isApplicantVisible = 'medium';
        }

        if ( status == 'Hired' )
        {
            this.isShowAllVisible = 'medium';
            this.isHiredVisible = 'success';
            this.isApplicantVisible = 'medium';
            this.isRejectedVisible = 'medium';
            this.isOnHoldVisible = 'medium';
        }

        if ( status == 'OnHold' )
        {
            this.isShowAllVisible = 'medium';
            this.isOnHoldVisible = 'primary';
            this.isApplicantVisible = 'medium';
            this.isRejectedVisible = 'medium';
            this.isHiredVisible = 'medium';
        }

        if ( status == 'ShowAll' )
        {
            this.selectedAppStatusFilter = null;
            this.isApplicantVisible = 'medium';
            this.isRejectedVisible = 'medium';
            this.isHiredVisible = 'medium';
            this.isOnHoldVisible = 'medium';
            this.isShowAllVisible = 'tertiary';
            this.showAllStatuses = true;
        }
        else
        {
            this.showAllStatuses = false;
        }

        this.filterCoreLogic();
    }

    filterApplicationType( appType: string )
    {
        this.selectedAppTypeFilter = appType;

        if ( appType == 'driver' )
        {
            this.isDriverVisible = 'light';
            this.isAzDriverVisible = 'medium';
            this.isDzDriverVisible = 'medium';
            this.isLabourVisible = 'medium';
            this.isClericalVisible = 'medium';
            this.isShowAllAppTypesVisible = 'medium';
        }

        if ( appType == 'labour' )
        {
            this.isDriverVisible = 'medium';
            this.isAzDriverVisible = 'medium';
            this.isDzDriverVisible = 'medium';
            this.isLabourVisible = 'secondary';
            this.isClericalVisible = 'medium';
            this.isShowAllAppTypesVisible = 'medium';
        }

        if ( appType == 'clerical' )
        {
            this.isDriverVisible = 'medium';
            this.isAzDriverVisible = 'medium';
            this.isDzDriverVisible = 'medium';
            this.isLabourVisible = 'medium';
            this.isClericalVisible = 'primary';
            this.isShowAllAppTypesVisible = 'medium';
        }

        if ( appType == 'azDriver' )
        {
            this.isDriverVisible = 'medium';
            this.isAzDriverVisible = 'light';
            this.isDzDriverVisible = 'medium';
            this.isLabourVisible = 'medium';
            this.isClericalVisible = 'medium';
            this.isShowAllAppTypesVisible = 'medium';
        }

        if ( appType == 'dzDriver' )
        {
            this.isDriverVisible = 'medium';
            this.isAzDriverVisible = 'medium';
            this.isDzDriverVisible = 'light';
            this.isLabourVisible = 'medium';
            this.isClericalVisible = 'medium';
            this.isShowAllAppTypesVisible = 'medium';
        }

        if ( appType == 'ShowAll' )
        {
            this.selectedAppTypeFilter = null;
            this.isDriverVisible = 'medium';
            this.isAzDriverVisible = 'medium';
            this.isDzDriverVisible = 'medium';
            this.isLabourVisible = 'medium';
            this.isClericalVisible = 'medium';
            this.isShowAllAppTypesVisible = 'tertiary';
            this.showAllAppTypes = true;
        }

        else
        {
            this.showAllAppTypes = false;
        }

        this.filterCoreLogic();
    }

    async filterCoreLogic()
    {
        this.applicants = this.dbApplicants;
        this.searching = true;
        let original = new Date();
        let subtract5min = new Date();

        subtract5min.setTime( original.getTime() - 5 * 60 * 1000 );

        this.applicants = this.applicants.filter( applicant =>
        {
            if ( applicant.Company == this.selectedCompany || this.selectedCompany == "all" )
            {
                if ( applicant.applicationStatus == undefined )
                    applicant.applicationStatus = 'Applicant';

                let active = new Date( applicant.lastActive );

                if ( ( new Date() > active ) && ( active > subtract5min ) )
                    return applicant.name;

                if ( this.selectedAppStatusFilter == null && this.selectedAppTypeFilter == null )
                    return applicant.name;

                if ( this.selectedAppStatusFilter == null && this.selectedAppTypeFilter != null )
                {
                    if ( applicant.applicationType == this.selectedAppTypeFilter )
                        return applicant.name;
                }

                if ( this.selectedAppStatusFilter != null && this.selectedAppTypeFilter == null )
                {
                    if ( applicant.applicationStatus == this.selectedAppStatusFilter )
                        return applicant.name;
                }

                if ( applicant.applicationStatus == this.selectedAppStatusFilter && applicant.applicationType == this.selectedAppTypeFilter )
                    return applicant.name;

                this.searching = false;
            }


        } );

        this.addSelfie();
    }

    initializeApp()
    {
        this.platform.ready().then( () =>
        {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        } );
    }

    ngAfterViewInit()
    {
        this.platform.ready().then( async () =>
        {
            await this.notificationsService.requestPermission();
        } );
    }

    async ngOnInit()
    {
        this.listenForLoginEvents();
        if ( this.isLoggedIn )
        {
            this.initializeApplicants();
            await this.notificationsService.init();
        }

        const path = window.location.pathname.split( 'applicant/' )[1];
        if ( path !== undefined )
        {
        }
    }

    successCallback( data: FirebaseUISignInSuccessWithAuthResult )
    {
        this.subscription = this.dataService.getUser( data.authResult.user.email )
            .subscribe( async user =>
            {
                this.subscription.unsubscribe();
                if ( user )
                {
                    if ( !user.isAdmin )
                    {
                        const toast = await this.toastCtrl.create( {
                            message: `User '${data.authResult.user.email}' is not an Admin to login`,
                            duration: 3000
                        } );
                        toast.present();
                        this.logout();
                        return;
                    }

                    if ( !!user ?.disabled)
                    {
                        const toast = await this.toastCtrl.create( {
                            message: `User '${data.authResult.user.email}' is disabled, Please contact Administrator.`,
                            duration: 3000
                        } );
                        toast.present();
                        this.logout();
                        return;
                    }

                }
                const adminUser = {
                    uid: data.authResult.user.uid,
                    email: data.authResult.user.email,
                    displayName: data.authResult.user.displayName,
                    photoURL: data.authResult.user.photoURL,
                    emailVerified: data.authResult.user.emailVerified,
                    providerId: data.authResult.user.providerId,
                    tenantId: data.authResult.user.tenantId,
                    refreshToken: data.authResult.user.refreshToken,
                    isAdmin: data.authResult.additionalUserInfo.isNewUser ? false : user.isAdmin
                };
                if ( adminUser.isAdmin )
                {
                    this.userService.login( adminUser ).then();
                    window.localStorage.setItem( 'loggedInUser', JSON.stringify( adminUser ) );
                    this.initializeApplicants();
                }
                else
                {
                    this.dataService.addAuthenticatedUser( adminUser );
                    const toast = await this.toastCtrl.create( {
                        message: `User '${data.authResult.user.email}' has been created, Please contact Administrator to assign permissions.`,
                        duration: 3000
                    } );
                    toast.present();
                    this.logout();
                }
            } );

    }

    errorCallback( data: FirebaseUISignInFailure )
    {
        console.log( data );
    }

    updateLoggedInStatus( loggedIn: boolean )
    {
        setTimeout( () =>
        {
            this.isLoggedIn = loggedIn;
            window.localStorage.setItem( 'isLoggedIn', loggedIn.toString() );
        }, 300 );
    }

    listenForLoginEvents()
    {
        window.addEventListener( 'user:login', () =>
        {
            this.updateLoggedInStatus( true );
        } );

        window.addEventListener( 'user:signup', () =>
        {
            this.updateLoggedInStatus( true );
        } );

        window.addEventListener( 'user:logout', () =>
        {
            this.updateLoggedInStatus( false );
        } );
    }

    logout()
    {
        this.isLoggedIn = false;
        window.localStorage.setItem( 'isLoggedIn', 'false' );
        window.localStorage.setItem( 'loggedInUser', '' );
        this.userService.logout().then( () =>
        {
            localStorage.clear();
            return this.router.navigate( ['/'] );
        } );
    }

    inAppMessageAlert()
    {
        this.dataService.getMessagesNotifications()
            .subscribe( ( querySnapshot ) =>
            {
                querySnapshot.forEach( ( doc ) =>
                {
                    let messages = doc.messages;
                    let msg = messages[messages.length - 1];
                    let applicantNotification = this.dbApplicants.find( x => x.email == msg.senderId );
                    if ( applicantNotification.newMessageStyle != 'dark' )
                    {
                        if ( applicantNotification.Company == this.selectedCompany ||
                            this.selectedCompany == "all" )
                        {
                            let audio = new Audio();
                            audio.src = "../../assets/sounds/ping.wav";
                            audio.load();
                            audio.play();
                            this.presentToast( `User: ${msg.senderId} sent: ${msg.messageBody}` );
                        }
                        applicantNotification.newMessageStyle = 'dark';
                    }
                } );
            } );
    }

    openApplication( email, index )
    {
        this.selectedIndex = index;
        if ( this.applicants.find( x => x.email == email ).newMessageStyle == 'dark' )
        {
            this.applicants.find( x => x.email == email ).newMessageStyle = '';
            this.dataService.readMessage( email );
        }
    }

    async presentToast( text )
    {
        const toast = await this.toastCtrl.create( {
            message: text,
            duration: 7000,
            position: 'top'
        } );
        toast.present();
    }

    async manageUsers()
    {
        const modal = await this.modalController.create( {
            component: UserManagePage,
        } );

        await modal.present();
        const data = await modal.onWillDismiss();
    }

    onCompanyChange( companyName )
    {
        console.log('CNAME',companyName)
        console.log('dbApplicants',this.dbApplicants)
        if ( companyName == "all" )
        {
            this.applicants = this.dbApplicants;
            this.selectedCompany = "all";
        }
        // else 
        // {
            this.selectedCompany = this.companies.find( x => x.name == companyName ).name;
            this.applicants = this.dbApplicants.filter( applicant =>
            {
                if ( applicant.Company == companyName )
                    return applicant;
            } );
        // }
        this.selectedIndex = -1;
        this.router.navigate( ['/'] );
        this.filterCoreLogic();
    }
}
