import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { first } from 'rxjs/operators';
import { GenericTemplate } from '../generic-view/generic-view.component';
import { User } from '../interfaces/user';
import { Storage } from '@ionic/storage';
import * as admin from 'firebase-admin';


@Injectable( {
    providedIn: 'root',
} )

export class DataStore
{
    applicationTypes: Observable<any[]>;
    companies: Observable<any[]>;
    documentTypes: Observable<any[]>;
    authenticatedUsers: Observable<User[]>;
    private userEmailLocal: string;
    get userEmail()
    {
        return this.userEmailLocal ?? JSON.parse( localStorage.getItem( 'current_user_email' ) );
    }
    set userEmail( value )
    {
        this.userEmailLocal = value;
    }


    constructor(
        private firestorage: AngularFireStorage,
        private firestore: AngularFirestore,
        private storage: Storage )
    {
        this.applicationTypes = firestore
            .collection( 'applicationTypes' )
            .valueChanges();

        this.companies = firestore
            .collection( 'companies' )
            .valueChanges();


        this.documentTypes = firestore.collection( 'documentTypes' ).valueChanges();

        this.authenticatedUsers = firestore
            .collection<User>( 'users' )
            .valueChanges();
        storage.get( 'user' ).then( email =>
        {
            this.userEmail = email ?.email;
        } );
    }

    getUser( userEmail: string ): Observable<any>
    {
        return this.firestore.collection( 'users' )
            .doc( userEmail ).valueChanges();

    }

    getMobileUsers(): Observable<any>
    {
        return this.firestore.collection( 'userApplications' ).valueChanges();
    }


    getMessagesNotifications(): Observable<any>
    {
        return this.firestore.collection( '/messages',
            ref => ref.where( 'showNotification', '==', true ) )
            .valueChanges();
    }

    getUserApplication( email: string ): Observable<any>
    {
        return this.firestore
            .doc<any>( 'userApplications/' + email )
            .valueChanges();
    }

    getStoredDocument( url: string ): any
    {
        const ref = this.firestorage.storage.ref( url );
        console.log(url);
        return ref.getDownloadURL();
    }

    archiveUserApplication( userEmail: string )
    {
        this.firestore.collection( 'userApplications' )
            .doc( userEmail )
            .set( { archived: true }, { merge: true } );
    }

    updateUserStatus( userEmail: string, active: boolean )
    {
        this.firestore.collection( 'users' )
            .doc( userEmail )
            .set( { Company: !active }, { merge: true } );
    }

    updateApplicationCompany( userEmail: string, company: string )
    {
        this.firestore.collection( 'userApplications' )
            .doc( userEmail )
            .set( { Company: company }, { merge: true } );
    }

    getStoredDocument2( url: string ): any
    {
        const ref = this.firestorage.storage.ref( url );
        ref.getDownloadURL().then( a =>
        {
            return a;
        } );
    }

    updateUserApplication( userApply: any )
    {
        this.firestore
            .collection( 'userApplications' )
            .doc( userApply.email )
            .update( Object.assign( {}, userApply ) );
    }

    getGenericStages(): Observable<any[]>
    {
        return this.firestore.collection<any>( 'genericTypes' ).valueChanges();
    }

    getGenericTemplates( name: string ): Observable<any>
    {
        var template = this.firestore.collection<GenericTemplate>( 'genericTemplates' ).doc( name )
            .valueChanges();
        return template;
    }

    getMessages( userEmail: string ): Observable<any>
    {
        return this.firestore.collection( 'messages' )
            .doc( userEmail )
            .valueChanges();
    }

    messagesWatch(): Observable<any>
    {
        return this.firestore.collection( 'messages' )
            .snapshotChanges();
    }

    createMessage( userEmail: string, message: string )
    {
        const messageData = {
            senderId: 'Admin - support',
            messageBody: message,
            timeStamp: Date()
        };

        return this.firestore
            .collection( 'userApplications' )
            .doc( userEmail )
            .collection( 'messages' )
            .doc( 'AdminChat' )
            .set( { messages: firebase.firestore.FieldValue.arrayUnion( messageData ) } );
    }

    sendMessage( userEmail: string, message: string )
    {
        const messageData = {
            senderId: 'Admin - support',
            messageBody: message,
            timeStamp: Date(),
            toId: userEmail
        };

        return this.firestore
            .collection( 'messages' )
            .doc( userEmail )
            .set( { messages: firebase.firestore.FieldValue.arrayUnion( messageData ) }, { merge: true } );
    }


    readMessage( userEmail: string )
    {
        var messages = this.firestore.collection( 'messages' ).doc( userEmail );

        var setWithMerge = messages.set( {
            showNotification: false
        }, { merge: true } );
    }

    addAuthenticatedUser( user: User )
    {
        this.firestore
            .collection( 'users' )
            .doc( user.email )
            .set( Object.assign( {}, user ), { merge: true } );
    }

    logActivity( action: string, info: any )
    {
        const userActivity = {
            timeStamp: Date(),
            deviceInfo: null,
            action,
            info,
        };

        this.getUserEmail().then( email =>
        {
            this.firestore
                .collection( 'userActivities' )
                .doc( email )
                .collection( action ).doc( Date() ).set( Object.assign( {}, userActivity ) );
        } );
    }

    getUserEmail(): Promise<string>
    {
        return this.storage.get( 'user' ).then( user => user.email );
    }

    uploadDocumentBlob( filePath: string, file: Blob )
    {
        return this.firestorage.storage.ref( filePath ).put( file );
    }

    makeAdmin(user: User) {
        return this.firestore.collection('users').doc(user.email).set( {
            isAdmin: !user.isAdmin
        }, { merge: true } )
    }
}


export interface Applicant
{
    url: string;
    progress: number;
    lastActive: string;
    applicationType: string;
    name: string;
}
